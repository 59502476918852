import { ProjectFilter } from './../components/projects/project-list/events/ProjectFilter';
import { MapAdaptor } from './../services/util/MapAdaptor';

import { Feature, Map } from 'ol';
import { toLonLat } from 'ol/proj';

import isequal from 'lodash.isequal';
import uniqwith from 'lodash.uniqwith';

// aurelia
import { LogManager, autoinject, bindable } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { DialogService } from 'aurelia-dialog';
import { Router, RouterConfiguration } from 'aurelia-router';
import { Subscription } from 'aurelia-event-aggregator';

import { ProjectEditor } from '../components/projects/project-edit/project-edit';
import { defaultMap } from './../components/projects/data/defaultMap';

import { ActionService } from './../services/actions/ActionService';
import { BrowserStorage } from '../services/util/BrowserStorage';
import { AlertService } from '../services/util/Alert';

import { GeocodeResult } from '@wsb_dev/datafi-shared/lib/types/Geocode';
import { Events } from '@wsb_dev/datafi-shared/lib/types/Events';
import { ActiveProgram } from '../services/util/ActiveProgram';

import { subscribe } from '../util/decorators/subscribe';
import { ValidatedProject } from '../types/Project';
import { debounced } from '../util/decorators/debounced';
import { Geometry } from 'ol/geom';

import { ProjectBreadcrumbsService } from '../services/assets/ProjectBreadcrumbs';
import { ProgramUserRole, Project } from '@wsb_dev/datafi-shared/lib/types';
import { DatafiProAPI } from '../services/api/DatafiProAPI';
import { EventAggregatorWrapper } from '../util/events/eventAggregatorWrapper';
import { ValidatedUser } from '../types/Users';
import { IThumbnailOptions } from '../components/ol-map/styles/thumbnailStyle';
import PQueue from 'p-queue';
import { StorageItem } from '../services/util/_StorageBase';
import { AppConfig } from '../services/util/AppConfig';
import { DFPUrlObj, IUrlParams, createLayerCacheKey } from '../components/ol-map/sources/dfpSourceUrlGeojson';
import Cluster from 'ol/source/Cluster';
import VectorSource from 'ol/source/Vector';
import dayjs from 'dayjs';

const log = LogManager.getLogger('dfp:projects');

@subscribe({
    events: [
        { eventEmitter: 'api.projects', event: 'created', fn: 'onProjectCreated' },
        { eventEmitter: 'api.projects', event: 'removed', fn: 'onProjectRemoved' },
        { eventEmitter: 'api.programUsersRoles', event: 'patched', fn: 'onUserPatched' },

        { eventEmitter: 'ea', event: Events.OL_MAP_CREATED, fn: 'setupMap' },
        { eventEmitter: 'ea', event: ProjectFilter, fn: 'handleProjectFilter' },
    ],
})
@autoinject
export class ProjectPage {
    /**============================================
     *               Data props
     *=============================================**/
    @bindable expanded: boolean;
    @bindable mapVisible: boolean;
    programId: number;

    @bindable projects: any[] = [];

    @bindable address: Feature<Geometry>;
    @bindable addresses: GeocodeResult[] = [];
    @bindable addressSearch: string;
    @bindable programUsersVisible: boolean;

    isEditing: boolean;

    mapOptions: any;
    map: Map;
    mapLoading: boolean;
    projectsQueue: PQueue;

    router: Router;

    subs: Subscription[] = [];

    projectQuery: Record<string, any> = {};
    projectQueryCache: string;

    users: ValidatedUser[] = [];

    constructor(
        private api: DatafiProAPI,
        private ea: EventAggregatorWrapper,
        private dialogService: DialogService,
        private storage: BrowserStorage,
        private alerts: AlertService,
        private actions: ActionService, // used in view
        private program: ActiveProgram,
        private mapAdaptor: MapAdaptor,
        private breadcrumbs: ProjectBreadcrumbsService,
        private config: AppConfig,
    ) {

        // expand/hide map
        this.storage.get('projects_expanded')
            .then((expanded) => {
                this.expanded = expanded.data;
                this.expandedChanged(this.expanded);
            })
            .catch((e) => {
                log.debug(e);
                this.expanded = false;
                this.expandedChanged(this.expanded);
            });
    }

    /**============================================
     *               Event handlers
     *=============================================**/

    onProjectCreated = (project: Project): void => {
        if ((this.api.auth.me.organizationId === project.organizationId) || project.organizationId === 0) {
            this.api.projects.get(project.id, { query: { $select: ['geom'], $modify: 'toGeoJSON' } }).then((project) => {
                this.mapAdaptor.updateLayer({
                    layerId: 'projectFeatures',
                    create: [project],
                });
            });
        }
    }

    onProjectRemoved = (project: Project): void => {
        if (project.id === this.router.currentInstruction.params.projectId) {
            this.router.navigateToRoute('list');
        }

        this.mapAdaptor.updateLayer({
            layerId: 'projectFeatures',
            remove: [project],
        });

        for (let i = 0; i < this.projects.length; i++) {
            if (this.projects[i].id === project.id) {
                // remove project from our list
                this.projects = [
                    ...this.projects.slice(0, i),
                    ...this.projects.slice(i + 1),
                ];
                return;
            }
        }
    }

    onUserPatched = (user: ProgramUserRole): void => {
        if (!this.programUsersVisible || user.program_id !== this.program.id) {
            return;
        }
        this.api.programUsersRoles.get(user.id, { query: { $modify: { toGeoJSON: [] } } }).then((user) => {
            this.mapAdaptor.updateLayer({
                layerId: 'userFeatures',
                update: [user],
            });
        });
    }

    /**============================================
     *               Methods
     *=============================================**/

    async activate(params) {
        // set data model init
        if (params.programId === this.program.id) {
            return;
        }

        return this.program.load(params.programId, true).then(async () => {
            // get the cached program id
            const id = `projectQuery-${params.programId}`;
            const { data: projectQuery } = await this.storage.get(id)
                .catch((e) => ({ id: id, data: {} } as StorageItem));
            this.projectQuery = {
                ...this.program.projectQuery,
                ...projectQuery,
                program_id: this.program.id,
            };

            const mapConfig = this.program.configs?.find((config) => config.type === 'ol-map');
            this.mapOptions = mapConfig?.data || defaultMap;

        });

    }

    detached() {
        this.mapAdaptor.map = null;
        this.subs.forEach((sub) => sub.dispose());
        this.users.forEach((user) => user.destroy());
    }

    configureRouter(config: RouterConfiguration, router: Router) {
        config.map([
            // program level
            { route: '', redirect: 'list' },
            { name: 'list', route: ['list', 'list/:tabId'], moduleId: PLATFORM.moduleName('../components/projects/project-tabs/project-tabs') },
            { name: 'survey', route: 'survey/:surveyId', moduleId: PLATFORM.moduleName('../components/surveys/survey-list/survey-list') },
            { name: 'assets', route: ['assets'], moduleId: PLATFORM.moduleName('../components/assets/asset-type-list/asset-type-list') },
            { name: 'assets-table', route: ['assets/:typeId'], moduleId: PLATFORM.moduleName('../components/assets/asset-table/asset-table') },

            // project level
            { name: 'project-detail', route: ':projectId', moduleId: PLATFORM.moduleName('../components/projects/project-tabs/project-tabs')},
            { name: 'project-survey', route: ':projectId/survey/:surveyId', moduleId: PLATFORM.moduleName('../components/surveys/survey-list/survey-list') },
            { name: 'project-assets', route: [':projectId/assets'], moduleId: PLATFORM.moduleName('../components/assets/asset-type-list/asset-type-list') },
            { name: 'project-assets-table', route: [':projectId/assets/:typeId'], moduleId: PLATFORM.moduleName('../components/assets/asset-table/asset-table') },

            // asset

        ]);

        config.fallbackRoute('list');

        this.router = router;

    }

    expandedChanged(expanded) {
        this.storage.create({
            id: 'projects_expanded',
            data: expanded,
        });
        setTimeout(() => {
            this.mapVisible = !expanded;
            window.dispatchEvent(new Event('resize'));
            if (!this.mapVisible) {
                this.mapAdaptor.cancel();
                this.isEditing = false;
            }
        }, 600);
    }

    async programUsersVisibleChanged(visible) {
        if (!visible) {
            const layer = await this.mapAdaptor.getVectorLayer({layerId: 'userFeatures'});
            layer?.setVisible(false);
            return;
        }

        const layer = await this.mapAdaptor.updateLayer({
            layerId: 'userFeatures',
            createLayer: true,
            layerOptions: {
                style: {
                    type: 'style/thumbnailStyle',
                    thumbnailField: 'user._blobs.profile.url',
                    labelField: 'user.fullname',
                } as IThumbnailOptions,
                dfpReady: true,
                source: {
                    type: 'dfp/source/VectorDFP',
                    url: {
                        type: 'url/dfp/GeoJSON',
                        serviceType: 'programs-users-roles',
                        baseUrl: this.config.API_HOST,
                        programId: this.program.id,
                        typeId: 0,
                        query: {
                            $select: ['updatedAt'],
                            $eager: 'user',
                            updatedAt: {
                                $gt: dayjs().subtract(1, 'week').toDate(),
                            },
                        },
                    } as IUrlParams,
                },
            },
        });

        layer.setVisible(true);
        layer.getSource().refresh();
    }

    addressesChanged(addresses) {
        this.mapAdaptor.updateLayer({
            layerId: 'addressFeatures',
            create: addresses,
            clear: true,
            createLayer: true,
            geomField: 'geometry',
        });
    }

    async addressChanged(address) {
        if (!address) {
            return;
        }
        const feature = await this.mapAdaptor.getFeature({ name: address }, 'addressFeatures', 'name');
        this.mapAdaptor.zoom(feature);
    }

    // we use arrow syntax because it binds `this` to the function
    // and is necessary because we are passing this function to
    // a child component
    getAddresses = (searchValue, selectedValue) => {

        if (selectedValue) {
            return Promise.resolve(this.addresses);
        }

        if (!searchValue) {
            this.addresses = [];
            return Promise.resolve();
        }

        // refresh geocoder
        const extent = this.map.getView().getCenter();
        const p1 = toLonLat([extent[0], extent[1]]);

        return this.api.geocode.find({
            query: {
                $search: searchValue,
                $center: p1.join(','),
            },
        }).then((results: GeocodeResult[]) => {
            this.addresses = results;
            return this.addresses;
        });
    }

    addressDisplay(address) {
        if (!address) {
            return;
        }
        return address.properties.name;
    }

    async createProject() {
        this.mapAdaptor.clearSelection();
        this.isEditing = true;
        this.alerts.create({
            label: 'Click on the map to draw your project boundary',
            dismissable: true,
        });

        return this.mapAdaptor.create('projectFeatures').then(async ({ geometry, feature }) => {
            const removeDuplicateCoords = uniqwith(geometry.coordinates[0], isequal);
            if (removeDuplicateCoords.length < 3) {
                const source = await this.mapAdaptor.getVectorLayerSource({ layerId: 'projectFeatures' });
                source.removeFeature(feature);
                this.alerts.create({
                    label: 'Please add at least 3 points',
                    level: 'error',
                    dismissable: true,
                });
                return;
            }

            return this.dialogService.open({
                viewModel: ProjectEditor, model: new ValidatedProject({
                    program_id: this.program.id,
                    geom: JSON.stringify(geometry),
                }),
            }).whenClosed(async (result) => {
                if (result.wasCancelled) {
                    const source = await this.mapAdaptor.getVectorLayerSource({ layerId: 'projectFeatures' });
                    source.removeFeature(feature);
                    this.alerts.create({
                        label: 'New Project cancelled',
                        dismissable: true,
                    });
                    return;
                }

                this.alerts.create({
                    label: 'Project created',
                    level: 'success',
                    dismissable: true,
                });

                feature.setProperties(result.output);
            });
        }).catch((e) => {
            this.alerts.create({
                label: e.message,
                level: 'error',
                dismissable: true,
            });
        }).finally(() => this.isEditing = false);
    }

    async setupMap(map: Map) {
        this.map = map;
        this.mapAdaptor.map = this.map;
        this.createLayers();
        this.updateMapProjects(true);
    }

    handleProjectFilter(ev: ProjectFilter) {
        this.projectQuery = ev.query;
        this.updateMapProjects();
    }

    async createLayers() {
        this.projectQueryCache = createLayerCacheKey(this.projectQuery);
        const projectsLayer = await this.mapAdaptor.getVectorLayer({
            layerId: 'projectFeatures',
            createLayer: true,
            updateLayer: true,
            layerOptions: {
                dfpReady: true,
                visible: true,
                source: {
                    type: 'dfp/source/VectorDFP',
                    url: {
                        type: 'url/dfp/GeoJSON',
                        serviceType: 'projects',
                        baseUrl: this.config.API_HOST,
                        programId: this.program.id,
                        typeId: 0,
                    } as IUrlParams,
                },
            },
        });
        (projectsLayer.getSource()?.getUrl() as DFPUrlObj)?.setParams({ query: this.projectQuery }, projectsLayer);
        projectsLayer.setVisible(true);
    }

    @debounced(200)
    async updateMapProjects(force = false) {
        const query = this.projectQuery;
        const layer = await this.mapAdaptor.getVectorLayer({ layerId: 'projectFeatures' });
        if (!layer) {
            return;
        }
        let source = layer?.getSource() as VectorSource | Cluster;
        if (source instanceof Cluster) {
            source = source.getSource();
        }

        const url = source.getUrl() as DFPUrlObj;
        url.setParams({ query }, layer);

    }

    editProjectGeometry(project) {
        this.mapAdaptor.clearSelection();
        this.isEditing = true;

        this.alerts.create({
            label: 'Edit the project by dragging on its boundary points',
            dismissable: true,
        });

        this.mapAdaptor.edit(project, 'projectFeatures')
            .then((result) => {
                const geom = result.geometry;
                this.alerts.create({
                    label: 'Saving edits...',
                    dismissable: true,
                });
                return this.api.projects.patch(project.id, {
                    geom: JSON.stringify(geom),
                });
            })
            .then(() => {
                this.alerts.create({
                    label: 'Project updated',
                    level: 'success',
                    dismissable: true,
                });
            })
            .catch((e) => {
                this.alerts.create({
                    label: e.message,
                    level: 'error',
                    dismissable: true,
                });
            })
            .finally(() => this.isEditing = false);
    }
}
