import { ValidationController } from 'aurelia-validation';
import { ValidationControllerFactory } from 'aurelia-validation';

import { AlertService } from '../services/util/Alert';
import { autoinject } from 'aurelia-dependency-injection';
import { DatafiProAPI } from '../services/api/DatafiProAPI';
import { IUploadEventDetail } from '../components/mdc-upload-field/mdc-upload-field';
import { EditableUser } from '../types/EditableUser';
import { IGeneratePath, generatePath } from '@wsb_dev/datafi-shared/lib/util/files/generatePath';
import { LogManager } from 'aurelia-framework';

const log = LogManager.getLogger('dfp:profile-edit');
@autoinject
export class ProfileEdit {
    editObject: EditableUser;
    controller: ValidationController;
    editing = false;
    visible = true;

    constructor(
        private controllerFactory: ValidationControllerFactory,
        private alerts: AlertService,
        private api: DatafiProAPI,
    ) { }

    editProfile() {
        this.editing = true;
        this.editObject = new EditableUser(this.api.auth.me, this.api.files);
        this.editObject.getProfile();
        this.controller = this.controllerFactory.createForCurrentScope();
    }

    cancelEdit() {
        this.editing = false;
        this.editObject = new EditableUser(this.api.auth.me, this.api.files);
    }

    updateUserProfile($event: CustomEvent<IUploadEventDetail>){
        this.editObject.profileUrl = $event.detail.created[0].id;
    }

    saveEdit (user: EditableUser) {
        return this.controller.validate({
            object: user,
        }).then((result) => {
            if (!result.valid) {
                this.editing = true;
                throw new Error('Form is not valid');
            }
            const data: any = {
                email: user.email,
                fullname: user.fullname,
                profileUrl: user.profileUrl,
            };

            if (user.password) {
                data.password = user.password;
                data.$oldPassword = user.oldPassword;
            }

            return this.api.users.patch(user.id, data);
        }).then((user) => {
            this.editing = false;

            this.alerts.create({
                label: 'Profile saved successfully',
                dismissable: true,
            });

            Object.assign(this.api.auth.me, user);
            if(user.profileUrl){
                this.api.auth.me.getProfile(true);
            }

        }).catch((e) => {
            log.error(e);

            this.alerts.create({
                label: e.message,
                level: 'error',
                dismissable: true,
            });
        });
    }

    getUserImageName = (f: File): string => {
        return generatePath({
            name: 'profile',
            fileName: f.name,
            fileId: this.editObject.id,
        } as IGeneratePath) as string;
    }
}
